import * as React from 'react';
import { Suspense } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme: Theme) =>
    createStyles({
        container: {
            [theme.breakpoints.up('sm')]: {
                height: '100vh',
            },
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        internalContainer: {
            [theme.breakpoints.up('sm')]: {
                height: 'calc(100vh - 150px)',
            },
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    });

type Props = WithStyles<typeof styles>;

export function center(Comp: React.ComponentClass | React.FunctionComponent, inLayout: boolean = false): any {
    class WrappedComponent extends React.Component<Props> {
        public render() {
            const { classes, ...rest } = this.props;
            return (
                <div className={inLayout ? classes.internalContainer : classes.container}>
                    <Suspense fallback={<CircularProgress variant={'indeterminate'} />}>
                        <Comp {...rest} />
                    </Suspense>
                </div>
            );
        }
    }

    const styledComponent = withStyles(styles, { withTheme: true })(WrappedComponent);

    return styledComponent;
}
