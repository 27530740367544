export interface IEnvironmentConfig {
    endPoint: string;
    environment: string;
    version: string;
}

class Config {
    public config: IEnvironmentConfig;
    public baseUrl: string;
    public apiUrl: string;
    public adminApiUrl: string;
    public environment: string;
    public isDev: boolean = false;
    public version: string = '';

    public async load() {
        const response = await fetch('/environment.json');
        this.config = await response.json();
        this.baseUrl = this.config.endPoint;
        this.apiUrl = this.baseUrl + '/api/';
        this.adminApiUrl = this.apiUrl + 'admin/';
        this.environment = this.config.environment;
        this.isDev = this.environment === 'DEV';
        this.version = this.config.version;
    }
}

const config = new Config();

export default config;
