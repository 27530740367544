export enum OrderType {
    newOrder = 0,
    renewOrder = 1,
    transferOrder = 2,
    leiChangeRequest = 3
}

export enum LogLevel {
    debug = 0,
    info = 1,
    warn = 2,
    error = 3,
    fatal = 4
}

export enum ReportingExceptionCategory {
    dIRECT_ACCOUNTING_CONSOLIDATION_PARENT = 0,
    uLTIMATE_ACCOUNTING_CONSOLIDATION_PARENT = 1
}

export enum SapRequestType {
    customerCreate = 1,
    customerChange = 2,
    salesOrderCreditCard = 3,
    salesOrderInvoice = 4,
    invoiceStatus = 5
}

export enum LegalEntityEventGroupType {
    rEVERSE_TAKEOVER = 1,
    sTANDALONE = 2,
    cHANGE_LEGAL_FORM_AND_NAME = 3,
    cOMPLEX_CHANGE_LEGAL_FORM = 4
}

export enum EmailTemplateType {
    activationMail = 0,
    forgotPassword = 1,
    validationRequestNotAuthorizedPerson = 3,
    validationRequestRejected = 4,
    chatMessage = 5,
    activationCodeForAuthorizedPerson = 6,
    invoice = 7,
    pendingOrderReminder = 9,
    confirmationNewOrder = 10,
    confirmationRenewOrder = 11,
    confirmationTransfer = 12,
    confirmationTransferIntern = 13,
    confirmationLeiChangeRequest = 14,
    firstLeiExpirationReminder = 15,
    secondLeiExpirationReminder = 16,
    leiLapsedReminder = 17
}

export enum OrderStatus {
    created = 0,
    inValidation = 2,
    failedValidation = 3,
    confirmedByLou = 4,
    complete = 8,
    paymentPending = 10
}

export enum RelationshipQualifierCategoryType {
    uS_GAAP = 0,
    iFRS = 1,
    oTHER_ACCOUNTING_STANDARD = 2,
    gOVERNMENT_ACCOUNTING_STANDARD = 3
}

export enum FundType {
    managedByAnotherEntity = 1,
    selfManaged = 2,
    pension = 3
}

export enum PaymentType {
    postfinance = 0,
    invoice = 1
}

export enum UserRole {
    none = 0,
    lEiManager = 1,
    systemAdministrator = 2,
    trustee = 3,
    monitoring = 4,
    uidManager = 5
}

export enum FundRelationshipType {
    subUmbrella = 1,
    subFeeder = 2,
    managedByAnotherEntity = 3
}

export enum SapOrderStatus {
    orderCreated = 1,
    invoiceCreated = 2,
    invoiceCanceled = 3,
    orderCanceled = 4,
    invoicePaid = 5,
    invoicePartiallyPaid = 6
}

export enum Permission {
    none = 0,
    search = 1 << 0,
    downloadLeiRegister = 1 << 1,
    newLeiRequest = 1 << 2,
    manageMyLei = 1 << 3,
    manageMyAccount = 1 << 4,
    manageAllLeis = 1 << 5,
    all = 1 << 20
}

export enum SyncStatus {
    succeeded,
    failed,
    pending,
    simmulationOk,
    simulationFailed
}

export enum ValidationSources {
    pENDING = 1,
    eNTITY_SUPPLIED_ONLY = 2,
    pARTIALLY_CORROBORATED = 3,
    fULLY_CORROBORATED = 4
}

export enum RelationshipCategoryType {
    iS_DIRECTLY_CONSOLIDATED_BY = 0,
    iS_ULTIMATELY_CONSOLIDATED_BY = 1,
    iS_INTERNATIONAL_BRANCH_OF = 2,
    iS_FUNDMANAGED_BY = 3,
    iS_SUBFUND_OF = 4,
    iS_FEEDER_TO = 5
}

export enum PreCheckResultState {
    pASS,
    nOT_APPLICABLE,
    fAIL,
    eXCEPTION
}

export enum CommonDataFileContent {
    lOU_FULL_INTERNAL,
    lOU_DELTA_INTERNAL,
    lOU_FULL_PUBLISHED,
    lOU_DELTA_PUBLISHED,
    gLEIF_FULL_INTERNAL,
    gLEIF_DELTA_INTERNAL,
    gLEIF_FULL_PUBLISHED,
    gLEIF_DELTA_PUBLISHED,
    qUERY_RESPONSE
}

export enum ExpirationReason {
    dISSOLVED = 1,
    cORPORATE_ACTION = 2,
    oTHER = 3
}

export enum GleifFileType {
    cDF21 = 1,
    rR11 = 2,
    pRD11 = 3,
    rEPEX11 = 4,
    rR11PNI = 5,
    cDF10 = 6,
    cDF31 = 7,
    rR21 = 8,
    rEPEX21 = 9
}

export enum LeiStatus {
    pENDING_VALIDATION = 1,
    iSSUED = 2,
    dUPLICATE = 3,
    lAPSED = 4,
    rETIRED = 6,
    aNNULLED = 7,
    cANCELLED = 8,
    tRANSFERRED = 9,
    pENDING_TRANSFER = 10,
    pENDING_ARCHIVAL = 11
}

export enum ValidationDocuments {
    aCCOUNTS_FILING = 1,
    rEGULATORY_FILING = 2,
    sUPPORTING_DOCUMENTS = 3,
    cONTRACTS = 4,
    oTHER_OFFICIAL_DOCUMENTS = 5
}

export enum DocumentFormat {
    pdf = 0,
    docx = 1
}

export enum GleifCheckState {
    initialCallForLevel1 = 0,
    initialResponseForLevel1 = 1,
    feedbackNoDuplicateCall = 2,
    feedbackDuplicateCall = 3,
    done = 8,
    initialPreCheckRequest = 9,
    initialPreCheckResponse = 10
}

export enum LegalEntityEventType {
    cHANGE_LEGAL_NAME = 1,
    cHANGE_OTHER_NAMES = 2,
    cHANGE_LEGAL_ADDRESS = 3,
    cHANGE_HQ_ADDRESS = 4,
    cHANGE_LEGAL_FORM = 5,
    dEMERGER = 6,
    sPINOFF = 7,
    aBSORPTION = 8,
    aCQUISITION_BRANCH = 9,
    tRANSFORMATION_BRANCH_TO_SUBSIDIARY = 10,
    tRANSFORMATION_SUBSIDIARY_TO_BRANCH = 11,
    tRANSFORMATION_UMBRELLA_TO_STANDALONE = 12,
    bREAKUP = 13,
    mERGERS_AND_ACQUISITIONS = 14,
    bANKRUPTCY = 15,
    lIQUIDATION = 16,
    vOLUNTARY_ARRANGEMENT = 17,
    iNSOLVENCY = 18,
    dISSOLUTION = 19
}

export enum UidSyncType {
    exportToUidRsgister,
    importFromUidRegister
}

export enum RelationshipRegistrationStatus {
    pENDING_VALIDATION = 0,
    pUBLISHED = 1,
    dUPLICATE = 2,
    lAPSED = 3,
    rETIRED = 4,
    aNNULLED = 5,
    tRANSFERRED = 6,
    pENDING_TRANSFER = 7,
    pENDING_ARCHIVAL = 8
}

export enum LegalEntityEventSource {
    uid = 1,
    admin = 2,
    order = 3
}

export enum EmailReminderType {
    firstLeiExpirationReminder,
    secondLeiExpirationReminder,
    lapsedLeiReminder
}

export enum TransferType {
    transferFromAccount = 0,
    transferFromLou = 1
}

export enum LegalEntityEventAffectedFieldName {
    legalName = 1,
    otherEntityNames = 2,
    legalAddressStreet = 3,
    legalAddressCity = 4,
    legalAddressZip = 5,
    legalAddressAdditionalLine = 6,
    legalAddressCountry = 7,
    legalAddressRegion = 8,
    headquarterAddressStreet = 9,
    headquarterAddressCity = 10,
    headquarterAddressZip = 11,
    headquarterAddressAdditionalLine = 12,
    headquarterAddressCountry = 13,
    headquarterAddressRegion = 14,
    legalJurisdiction = 15,
    entityCategory = 16,
    entityLegalFormCode = 17,
    otherLegalForm = 18,
    successorLei = 19,
    successorEntity = 20,
    relationshipLei = 21,
    relationshipType = 22,
    repexCategory = 23,
    repexReason = 24
}

export enum TranslationType {
    customerPortal = 0,
    adminPortal = 1,
    server = 2
}

export enum DocumentTemplateType {
    invoice = 0,
    authorisationLetter = 1,
    gleifCert = 5,
    xSD_CDF = 6,
    xSD_RR = 7,
    xSD_PRD = 8,
    xSD_REPEX = 9,
    leiCert = 10,
    newOrderConfirmation = 12,
    transOrderConfirmation = 13,
    renewOrderConfirmation = 14,
    transferRequestForm = 15,
    sapCert = 16
}

export enum ReportingExceptionReason {
    nO_LEI = 0,
    nATURAL_PERSONS = 1,
    nON_CONSOLIDATING = 2,
    nO_KNOWN_PERSON = 3,
    nON_PUBLIC = 9
}

export enum RelationshipPeriodsType {
    aCCOUNTING_PERIOD = 0,
    rELATIONSHIP_PERIOD = 1,
    dOCUMENT_FILING_PERIOD = 2
}

export enum Language {
    de = 1,
    fr = 2,
    it = 3,
    en = 4
}

export enum ProductType {
    organization = 0,
    fund = 1,
    trust = 2
}

export enum EntityStatus {
    aCTIVE = 1,
    iNACTIVE = 2,
    nULL = 3
}

export enum Salutation {
    mr = 0,
    mrs = 1,
    dr = 2
}

export enum GleifCheckType {
    level1 = 0,
    preCheck = 2
}

export enum AdminJobHistorySort {
    type = 0,
    startTime = 1,
    endTime = 2,
    suceeded = 3
}

export enum LogSort {
    logDate = 0,
    logLevel = 1,
    logger = 2,
    message = 3,
    instanceId = 4
}

export enum GleifDataSort {
    lEI = 0,
    legalName = 1,
    countryCode = 2,
    registerEntityId = 3,
    entityType = 4,
    status = 5,
    fileType = 6,
    syncDate = 7,
    modifiedDate = 8,
    modifiedBy = 9
}

export enum AdminJobQueueSort {
    active = 0,
    isRunning = 1,
    retryCount = 2,
    modifiedBy = 3,
    modifiedDate = 4
}

export enum RegistrationAuthoritySort {
    code = 0,
    countryCode = 1,
    jurisdiction = 2,
    nameOfRegister = 3,
    localNameOfRegister = 4,
    nameOfOrganisation = 5,
    localNameOfOrganisation = 6,
    sortOrder = 7,
    isActive = 8,
    modifiedBy = 9,
    modifiedDate = 10
}

export enum NationalHolidaySort {
    description = 0,
    date = 1,
    modifiedBy = 2,
    modifiedDate = 3
}

export enum OrderSort {
    reference = 0,
    type = 1,
    status = 2,
    leiManager = 3,
    lastUpdate = 4
}

export enum AdminRecurringJobSort {
    type = 0,
    cronExpression = 1,
    enabled = 2,
    lastRun = 3,
    lastRunState = 4
}

export enum SortDirection {
    aSC = 0,
    dESC = 1
}

export enum RecurringJobType {
    gleifUpload = 0,
    gleifDownload = 1,
    firstLeiExpirationReminder = 2,
    secondLeiExpirationReminder = 3,
    lapsedLeiExpirationReminder = 4,
    setLeiToLapsed = 5,
    updateFromUidRegister = 6,
    updateToUidRegister = 7,
    sendPendingOrderReminder = 8,
    sapInvoiceStatus = 10,
    swissTopo = 12,
    sapFakturaEscalation = 13,
    slaReport = 14
}

export enum RegionSort {
    code = 0,
    countryCode = 1,
    isActive = 2,
    textDe = 3,
    textEn = 4,
    textFr = 5,
    textIt = 6,
    sortOrder = 7,
    modifiedBy = 8,
    modifiedDate = 9
}

export enum AdminLeiHistorySort {
    createdDate = 0,
    initialRegistrationDate = 1,
    nextRenewalDate = 2,
    modifiedBy = 3
}

export enum EmailTemplateSort {
    emailTemplateType = 0,
    subjectDe = 1,
    subjectFr = 2,
    subjectIt = 3,
    subjectEn = 4,
    modifiedBy = 5,
    modifiedDate = 6
}

export enum LeiSort {
    name = 0,
    lei = 1,
    active = 2,
    status = 3,
    type = 4,
    nextRenewalDate = 5,
    manager = 6,
    noLapsedReminder = 7
}

export enum PniOrganizationSort {
    pni = 0,
    name = 1,
    registerNumber = 2,
    customHeadquarterAddress = 3,
    legalAddress = 4,
    headquarterAddress = 5,
    leiManager = 6,
    legalForm = 7,
    registrationAuthority = 8,
    modifiedBy = 9,
    modifiedDate = 10
}

export enum CommonDataFilePackageSort {
    fileName = 0,
    fileType = 1,
    contentType = 2,
    contentLength = 3,
    modifiedDate = 4
}

export enum InvoiceSort {
    paymentDate = 0,
    referenceNo = 1,
    totalPrice = 2,
    vatPercent = 3,
    vatAmount = 4,
    totalPriceInclVat = 5,
    processingFee = 6,
    organizationName = 7,
    address = 8
}

export enum OrderTicketSort {
    code = 0,
    letterSentDate = 1,
    expireDate = 2,
    orderNumber = 3,
    modifiedBy = 4,
    modifiedDate = 5
}

export enum ConfigSort {
    key = 0,
    value = 1,
    modifiedBy = 2,
    modifiedDate = 3
}

export enum LeiManagerSort {
    userName = 0,
    firstName = 1,
    lastName = 2,
    uid = 3,
    emailConfirmed = 4,
    locked = 5,
    deleted = 6
}

export enum SystemJobType {
    rescheduleJobs = 0,
    executeScheduledJobs = 1,
    oneTimeJobs = 2
}

export enum LegalFormSort {
    code = 0,
    countryCode = 1,
    sortOrder = 2,
    textDe = 3,
    textEn = 4,
    textFr = 5,
    textIt = 6,
    isActive = 7,
    modifiedBy = 8,
    modifiedDate = 9
}

export enum DocumentTemplateSort {
    documentTemplateType = 0,
    language = 1,
    version = 2,
    contentType = 3,
    modifiedBy = 4,
    modifiedDate = 5
}

export enum TranslationSort {
    key = 0,
    type = 1,
    textDe = 2,
    textEn = 3,
    textFr = 4,
    textIt = 5,
    modifiedBy = 6,
    modifiedDate = 7
}

export enum ScheduledJobType {
    email = 0,
    completeOrder = 1,
    gleifPreCheck = 2,
    sapSalesOrder = 3
}

export enum CountrySort {
    code = 0,
    isActive = 1,
    sortOrder = 2,
    textDe = 3,
    textEn = 4,
    textFr = 5,
    textIt = 6,
    modifiedBy = 7,
    modifiedDate = 8
}
