import { axiosClient, serviceError } from './axios';
import { getLanguageAsString } from '../utils/translationHelper';
import config from '../../../config';

export const TranslationService = {
    url(): string {
        return config.apiUrl + 'translations';
    },
    async fetch(): Promise<any> {
        try {
            const response = await axiosClient().get<any>(
                `${TranslationService.url()}/texts/${getLanguageAsString()}/1/1`,
                {
                    validateStatus: (status: number) => {
                        return status < 400;
                    },
                }
            );

            if (response.status === 304) {
                return undefined;
            }

            // localStorage.setItem('version', response.data.version);

            return response.data;
        } catch (error) {
            throw serviceError(error);
        }
    },
};
