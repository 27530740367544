class EventBus {
    private subscriptions: Function[] = [];

    public subscribe = (listener: Function) => {
        if (this.subscriptions.indexOf(listener) === -1) {
            this.subscriptions.push(listener);
        }
    };

    public publish = () => {
        this.subscriptions.forEach((x) => x());
    };
}

export default new EventBus();
