import {
    DocumentTemplateType,
    EmailTemplateType,
    ExpirationReason,
    Language,
    LeiStatus,
    LogLevel,
    OrderStatus,
    OrderType,
    ProductType,
    RecurringJobType,
    RelationshipQualifierCategoryType,
    ReportingExceptionReason,
    TranslationType,
    ValidationDocuments,
    ValidationSources,
    GleifFileType,
    ScheduledJobType,
    PreCheckResultState,
    FundType,
    LegalEntityEventType,
    LegalEntityEventSource,
    LegalEntityEventGroupType,
    LegalEntityEventAffectedFieldName,
    SapOrderStatus,
    SapRequestType,
    RelationshipCategoryType,
    RelationshipRegistrationStatus, FundRelationshipType,
} from '../dtos/generated/enums.generated';
import i18n from 'i18next';

const leiStatusPrefix = 'leistatus-';
const orderStatusPrefix = 'orderstatus-';
const orderTypePrefix = 'ordertype-';
const leiProductTypePrefix = 'producttype-';
const validationSourcesPrefix = 'validationSources-';
const leiRecurringJobTypePrefix = 'recurringJobType-';
const logLevelPrefix = 'logLevel-';
const emailTemplateTypePrefix = 'emailTemplateType-';
const documentTemplateTypePrefix = 'documentTemplateType-';
const languagePrefix = 'language-';
const reportingExceptionReasonPrefix = 'reporting-exception-reason-';
const relationshipQualifierCategoryTypePrefix = 'qualifier-category-type-';
const relationshipTypePrefix = 'relationship-type-';
const relationshipStatusPrefix = 'relationship-status-';
const validationDocumentsPrefix = 'validation-documents-';
const expirationReasonPrefix = 'expiration-reason-';
const translationTypePrefix = 'translation-type-';
const gleifFileTypePrefix = 'gleifFileType-';
const scheduledJobTypePrefix = 'scheduledJobType-';
const preCheckResultStatePrefix = 'precheck-result-';
const fundTypePrefix = 'fundType-';
const fundRelationshipTypePrefix ='fund-relationship-type-';

export const getLeiStatusLangKey = (status: any) => {
    return (leiStatusPrefix + LeiStatus[status]).toLowerCase();
};

export const getReportingExceptionReasonLangKey = (status: any) => {
    return (reportingExceptionReasonPrefix + ReportingExceptionReason[status]).toLowerCase();
};

export const getRelationshipQualifierCategoryTypeLangKey = (status: any) => {
    return (relationshipQualifierCategoryTypePrefix + RelationshipQualifierCategoryType[status]).toLowerCase();
};

export const getValidationDocumentLangKey = (status: any) => {
    return (validationDocumentsPrefix + ValidationDocuments[status]).toLowerCase();
};

export const getProductTypeLangKey = (status: any) => {
    return (leiProductTypePrefix + ProductType[status]).toLowerCase();
};

export const getOrderStatusLangKey = (status: any) => {
    return (orderStatusPrefix + OrderStatus[status]).toLowerCase();
};

export const getOrderTypeLangKey = (type: any) => {
    return (orderTypePrefix + OrderType[type]).toLowerCase();
};

export const getRecurringJobTypeLangKey = (type: any): string => {
    return (leiRecurringJobTypePrefix + RecurringJobType[type]).toLowerCase();
};

export const getValidationSourceLangKey = (status: any) => {
    return (validationSourcesPrefix + ValidationSources[status]).toLowerCase();
};

export const getLogLevelLangKey = (level: any): string => {
    return (logLevelPrefix + LogLevel[level]).toLowerCase();
};

export const getEmailTemplateLangKey = (type: any): string => {
    return (emailTemplateTypePrefix + EmailTemplateType[type]).toLowerCase();
};

export const getDocumentTemplateLangKey = (type: any): string => {
    return (documentTemplateTypePrefix + DocumentTemplateType[type]).toLowerCase();
};

export const getLanguageLangKey = (language: any): string => {
    return (languagePrefix + Language[language]).toLowerCase();
};

export const getExpirationReasonLangKey = (expirationReason: any): string => {
    return (expirationReasonPrefix + ExpirationReason[expirationReason]).toLowerCase();
};

export const getLegalEntityEventTypeLangKey = (value: any): string => {
    return ("legal-entity-event-type-" + LegalEntityEventType[value]).toLowerCase();
};

export const getSapRequestTypeLangKey = (value: any): string => {
    return ("sap-request-type-" + SapRequestType[value]).toLowerCase();
};

export const getLegalEntityEventSourceLangKey = (value: any): string => {
    return ("legal-entity-event-source-" + LegalEntityEventSource[value]).toLowerCase();
};

export const getLegalEntityEventGroupTypeLangKey = (value: any): string => {
    return ("legal-entity-event-group-type-" + LegalEntityEventGroupType[value]).toLowerCase();
};

export const getLegalEntityEventAffectedFieldName = (value: any): string => {
    return ("legal-entity-event-affected-fieldName-" + LegalEntityEventAffectedFieldName[value]).toLowerCase();
};

export const getLSapOrderStatusLangKey = (value: any): string => {
    return ("sap-order-status-" + SapOrderStatus[value]).toLowerCase();
};

export const getTranslationTypeLangKey = (translationType: any): string => {
    return (translationTypePrefix + TranslationType[translationType]).toLowerCase();
};

export const getGleifFileTypeLangKey = (fileType: any): string => {
    return (gleifFileTypePrefix + GleifFileType[fileType]).toLowerCase();
};

export const getFundTypeLangKey = (fileType: any): string => {
    return (fundTypePrefix + FundType[fileType]).toLowerCase();
};

export const getRelationshipTypeLangKey = (type: any): string => {
    return (relationshipTypePrefix + RelationshipCategoryType[type]).toLowerCase();
};

export const getFundRelationshipTypeLangKey = (type: any): string => {
    return (fundRelationshipTypePrefix + FundRelationshipType[type]).toLowerCase();
};

export const getRelationshipStatusLangKey = (status: any): string => {
    return (relationshipStatusPrefix + RelationshipRegistrationStatus[status]).toLowerCase();
};

export const getLanguage = (): Language => {
    const lang = i18n.language.length > 2 ? i18n.language.substring(2).toLowerCase() : i18n.language.toLowerCase();
    switch (lang) {
        case 'fr':
            return Language.fr;
        case 'it':
            return Language.it;
        case 'en':
            return Language.en;
        default:
            return Language.de;
    }
};

export const getLanguageAsString = (): string => {
    return Language[getLanguage()];
};

export const getScheduledJobTypeLangKey = (type: any): string => {
    return (scheduledJobTypePrefix + ScheduledJobType[type]).toLowerCase();
};

export const getPreCheckResultStateLangKey = (status: any) => {
    return (preCheckResultStatePrefix + PreCheckResultState[status]).toLowerCase();
};
