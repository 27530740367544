import axios, { AxiosError, AxiosInstance } from 'axios';
import { AuthDto } from '../dtos/authDto';
import { AppError } from '../models/appError';
import eventBus from '../../../shell/utils/eventBus';
import { getLanguageAsString } from '../utils/translationHelper';

export const authSessionStorageKey = 'auth';
export const getBearerToken = () => {
    const json = sessionStorage.getItem(authSessionStorageKey);
    if (json) {
        const authData = JSON.parse(json) as AuthDto;
        return authData.access_token;
    }
    return null;
};

export function axiosClient(): AxiosInstance {
    const clientId = 'web-admin-portal';
    const clientSecret = '5cjDNGrwDxjEZXf5tZ4iEJaBQmM5SxV2WZBvqb2oQ';

    return axios.create({
        timeout: 30000,
        headers: {
            Authorization: 'Basic ' + btoa(`${clientId}:${clientSecret}`),
            'content-type': 'application/json',
            'accept-language': getLanguageAsString(),
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
        },
    });
}

export function secureAxiosClient(): AxiosInstance {
    return axios.create({
        timeout: 60000,
        headers: {
            Authorization: `Bearer ${getBearerToken()}`,
            'content-type': 'application/json',
            'accept-language': getLanguageAsString(),
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
        },
    });
}

export const serviceError = (err: AxiosError) => {
    if (err.response) {
        if (err.response.status === 401 || err.response.status === 403) {
            eventBus.publish();
            return;
        }
        if (err.response.status === 400) {
            const data = err.response.data;
            if (data.error) {
                return new AppError(data.error);
            }
        } else {
            // application error
            let error = err.response.data;
            if (error.instanceId && error.translationKey && error.message) {
                return new AppError(error.translationKey, error.instanceId);
            }
        }
    }
    // unexcepted exception
    return new AppError('UnexpectedExeption');
};
