import { setLocale } from 'yup';

setLocale({
  mixed: {
    required: 'validation-required',
    notType: 'validation-invalid-format',
  },
  string: {
    email: 'validation-email',
    max: 'validation-maxlength',
  },
});
