import React, { Component, Suspense } from 'react';
import { observer } from 'mobx-react';
import 'react-app-polyfill/ie11';
import './utils/eventBus';
import ComponentLoading from './components/ComponentLoading';
import config from '../config';
import { observable, runInAction } from 'mobx';
import { TranslationService } from '../modules/common/services/translationService';
import i18n from 'i18next';
import i18Instance from '../i18n';
import { getLanguageAsString } from '../modules/common/utils/translationHelper';

const App = React.lazy(() => import('./App'));

@observer
class Bootstrapper extends Component {
    @observable configLoaded: boolean = false;

    public async componentDidMount() {
        await Promise.all([i18Instance, config.load()]);

        try {
            const response = await TranslationService.fetch();
            if (response) {
                i18n.addResourceBundle(getLanguageAsString(), 'translations', response, true, true);
            }
        } catch (error) {
            console.error(error);
        }

        runInAction(() => {
            this.configLoaded = true;
        });
    }

    render() {
        if (!this.configLoaded) {
            return <ComponentLoading />;
        }
        return (
            <Suspense fallback={<ComponentLoading />}>
                <App />
            </Suspense>
        );
    }
}

export default Bootstrapper;
