import React, { Component } from 'react';
import { CircularProgress, createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import { center } from '../../modules/common/hocs/center';

const styles = (theme: Theme) =>
    createStyles({
        progress: {
            margin: theme.spacing(2),
        },
    });

type Props = WithStyles<typeof styles>;

class ComponentLoading extends Component<Props> {
    render() {
        const { classes } = this.props;

        return (
            <div>
                <CircularProgress className={classes.progress} variant="indeterminate" color={'secondary'} />
            </div>
        );
    }
}

const styledComponent = withStyles(styles, { withTheme: true })(ComponentLoading);
const centeredComponent = center(styledComponent, false);

export default centeredComponent;
